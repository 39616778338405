import React, {useEffect, useState} from "react"
import Modal from "react-bootstrap/Modal";
import {Button, ModalBody, ModalFooter} from "react-bootstrap";
import {clinicService} from "../../../../service/clinic_service";
import {$$} from "../../../../helpers/localization";
import {useNhisExaminationContext} from "./NhisExaminationProvider";
import {
    nhisCreateOrUpdateExamination,
    nhisLogin,
    nhisSignExam
} from "../../../../service/nhis_service";
import {dateTimeUtils} from "../../../../utils/dateTimeUtils";
import {getClinicianNameWithTitle} from "../../../../utils/getClinicianNameWithTitle";
import PatientProviderForm from "./PatientProviderForm";

export default function UploadExamWizard({encounter, examination, patient, provider, orgId, onCancel, onSuccess, lang}) {
    const {
        isLoading,
        specialtyOptions,
        countyOptions
    } = useNhisExaminationContext();

    const [exam, setExam] = useState();
    const [error, setError] = useState();
    const [sendingInProgress, setSendingInProgress] = useState(false);
    const [hasEmptyDiagnosis, setHasEmptyDiagnosis] = useState(false);
    const [success, setSuccess] = useState(false);
    const [nrn, setNrn] = useState(false);
    const [editPersonalData, setEditPersonalData] = useState(false);
    const [patientInfo, setPatientInfo] = useState();

    useEffect(() => {
        let ex = JSON.parse(examination);
        setExam(ex);

        if (!ex.examination.diagnosis.code) {
            setHasEmptyDiagnosis(true);
        } else {
            if (ex.examination.additionalDiagnoses && ex.examination.additionalDiagnoses.length > 0) {
                setHasEmptyDiagnosis(ex.examination.additionalDiagnoses.some(d => !d.code));
            }
        }

        clinicService.fetchEncPatientInfo(patient.id, orgId).then(patient => {
            const names = patient.patientName.split(" ");
            patient.firstName = names[0];
            patient.familyName = names[names.length - 1];
            setPatientInfo(patient);
        });

    }, [examination])

    const onUpdate = (patient, provider) => {

        let localModel = exam.examination;

        let model = {
            lrn: localModel.lrn,
            closeDate: localModel.closeDate,
            openDate: localModel.openDate,
            class: localModel.classification,
            financingSource: localModel.financingSource,
            rhifAreaNumber: localModel.rhifAreaNumber,
            basedOn: localModel.basedOn ? localModel.basedOn : null,
            directedBy: localModel.directedBy,
            purpose: localModel.purpose,
            incidentalVisit: localModel.incidentalVisit,
            isSecondary: localModel.isSecondary,
            adverseConditions: localModel.adverseConditions,
            motherHealthcare_isPregnant: patient.gender === "2" && localModel.purpose === "4" ? localModel.isPregnant : null,
            motherHealthcare_isBreastFeeding: patient.gender === "2" && localModel.purpose === "4" ? localModel.isBreastFeeding : null,
            motherHealthcare_gestationalWeek: patient.gender === "2" && localModel.purpose === "4" && localModel.gestationalWeek > 0 && localModel.isPregnant ? localModel.gestationalWeek : null,
            diagnosis: {
                code: localModel.diagnosis.code,
                additionalCode: localModel.diagnosis.additionalCode && localModel.diagnosis.additionalCode.length > 0 ? localModel.diagnosis.additionalCode : null,
                use: localModel.diagnosis.use,
                rank: localModel.diagnosis.rank,
                clinicalStatus: localModel.diagnosis.clinicalStatus,
                verificationStatus: localModel.diagnosis.verificationStatus,
                onsetDateTime: localModel.diagnosis.onsetDateTime ? dateTimeUtils.toOffsetDateTime(localModel.diagnosis.onsetDateTime) : null,
                note: localModel.diagnosis.note ? localModel.diagnosis.note : null
            },
            comorbidity: localModel.additionalDiagnoses.length > 0 ? localModel.additionalDiagnoses.map(diagnosis => ({
                code: diagnosis.code,
                additionalCode: diagnosis.additionalCode && diagnosis.additionalCode.length > 0 ? diagnosis.additionalCode : null,
                use: diagnosis.use,
                rank: diagnosis.rank,
                clinicalStatus: diagnosis.clinicalStatus,
                verificationStatus: diagnosis.verificationStatus,
                onsetDateTime: diagnosis.onsetDateTime ? dateTimeUtils.toOffsetDateTime(diagnosis.onsetDateTime) : null,
                note: diagnosis.note ? diagnosis.note : null
            })) : null,
            therapy: localModel.medicationCodeOption || localModel.note ? {
                medicationCode: localModel.medicationCodeOption ? localModel.medicationCodeOption.map(o => o.value) : null,
                note: localModel.note ? localModel.note : null
            } : null,

            medicalHistory: localModel.medicalHistory ? localModel.medicalHistory : null,
            objectiveCondition: localModel.objectiveCondition ? localModel.objectiveCondition : null,
            conclusion: localModel.conclusion ? localModel.conclusion : null,
            dischargeDisposition: localModel.dischargeDisposition ? localModel.dischargeDisposition : null
        }

        if (encounter.update_nhis) {
            model.correctionReason = "Поправка.";
            model.nrnExamination = encounter.nhis_examination_nrn;
        }

        provider.role = "1";
        let message = encounter.update_nhis ? {
            subject_birthDate: patient.birthDate,
            examination: model,
            senderId: provider.pmi
        } : {
            examination: model,
            subject: patient,
            performer: provider,
            senderId: provider.pmi
        };

        setSendingInProgress(true);
        setError(undefined);
        nhisLogin((loginSuccess) => {
            nhisSignExam(message, encounter.update_nhis, (signedExam) => {
                nhisCreateOrUpdateExamination(loginSuccess.accessToken, signedExam, encounter.update_nhis)
                    .then(async res => {
                        let contents = res["nhis:message"]["nhis:contents"];
                        let error = contents["nhis:error"];
                        if (error) {
                            if (Array.isArray(error)) {
                                setError(error[0]["nhis:reason"]["value"]);
                            } else {
                                setError(error["nhis:reason"]["value"]);
                            }
                        } else {
                            let status = contents["nhis:status"]["value"];
                            //1 - open, 2-closed (success), 3-canceled
                            if (status == 2) {
                                setSuccess(true);
                                let nrn = contents["nhis:nrnExamination"]["value"];
                                setNrn(nrn);
                                onSuccess(nrn)
                            } else if (status == 3) {
                                setError("Неуспешно изпращане. Прегледът е със анулиран статус в системата на НЗИС!")
                            } else {
                                setError("Неуспешно изпращане. Прегледът е със статус ОТВОРЕН в системата на НЗИС!")
                            }
                        }
                        setSendingInProgress(false)
                    })
            }, (e) => {
                setError(e);
                setSendingInProgress(false);
            })
        }, (e) => {
            setSendingInProgress(false);
            setError(e);
        }).catch(error => {
            console.log(error);
            setSendingInProgress(false);
            setError($$("nhis.service_error"));
        })
    }

    return <Modal backdrop="static" show={true} size={"lg"}>
        <ModalBody className="nhis-prescription-modal-body">
            {isLoading &&
            <div className={"text-center mt-4"}>{$$("nhis.loading_data_message")}</div>
            }
            {error && <div className="text-danger text-center p-2">{error}</div>}
            {success && <div className="text-left">
                <p className="text-center">{$$("nhis.examination.successfully_sent_to_nhis")}</p>
                <h5 className="text-center">{$$("nhis.examination.nrn_number")}: {nrn}</h5>
            </div>}
            {!isLoading && exam && !hasEmptyDiagnosis && !success && editPersonalData &&
            <div className={`${sendingInProgress ? "d-none" : ''}`}>
                <PatientProviderForm countyOptions={countyOptions}
                                     subject={patient}
                                     provider={provider}
                                     specialtyOptions={specialtyOptions}
                                     orgId={orgId}
                                     exam={exam}
                                     onUpdate={onUpdate}
                                     lang={lang}
                />
            </div>
            }
            {!isLoading && exam && !hasEmptyDiagnosis && !success && !editPersonalData && patient &&
            <div className={`${sendingInProgress ? "d-none" : ''}`}>
                <div><span className="medrec-grey-2">{$$("nhis.patient.title")}: </span>{`${patientInfo.firstName} ${patientInfo.familyName}`}</div>
                <div><span className="medrec-grey-2">{$$("clinician_label")}: </span>{getClinicianNameWithTitle(provider)}</div>
                <div><span className="medrec-grey-2">{$$("nhis.examination.results")}</span>
                    <div className="ml-3">
                        <div><span className="medrec-grey-2">{$$("main_diagnosis_label")}: </span>{encounter.main_diagnosis}</div>
                        {encounter.concomitant_diseases.length > 0 && <div>
                            <span className="medrec-grey-2">{$$("concomitant_diseases_label")}: </span>
                                {encounter.concomitant_diseases.join("; ")}</div>}
                        {encounter.therapy && <div className="d-flex"><div className="medrec-grey-2">{$$("therapy_label")}:</div><div className="whitespace-pre-line pl-1">{encounter.therapy}</div></div>}
                    </div>
                </div>
            </div>
            }
            {hasEmptyDiagnosis && !isLoading &&
            <div className="text-danger text-center p-2">{$$("nhis.examination.has_empty_diagnosis")}</div>}
            {sendingInProgress &&
            <div><p className="text-center">{$$("nhis.processing_body")}</p>
            </div>
            }
        </ModalBody>
        {!success && !isLoading && <ModalFooter>
            {!sendingInProgress && !isLoading &&
                /*<Button variant={"primary"} form={"patient_provider_form"} type={"submit"} disabled={hasEmptyDiagnosis}>
                    {$$("nhis.btn.complete_exam")}
                </Button>*/
                <Button variant={"primary"} type={"button"} disabled={hasEmptyDiagnosis} onClick={()=>{onUpdate(exam.subject, exam.performer)}}>
                    {$$("nhis.btn.complete_exam")}
                </Button>
            }
            {!sendingInProgress && !isLoading &&
            <Button variant={"outline-primary"} type={"button"} onClick={onCancel}>{$$("close_btn_label")}</Button>}
        </ModalFooter>
        }
        {success && <ModalFooter>
            <Button variant={"primary"} form={"patient_provider_form"} type={"button"} disabled={hasEmptyDiagnosis}
                    onClick={onCancel}>{$$("close_btn_label")}</Button>
        </ModalFooter>}
    </Modal>
}


