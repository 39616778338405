import React, {useEffect, useState} from "react"
import Modal from "react-bootstrap/Modal";
import {$$} from "../../../../helpers/localization";
import moment from "moment"

export default function NhisConvertToNhisExamDialog({encounter, onClose, onConvert}) {
    const [success, setSuccess] = useState(false);

    const convert = async () => {
        let enc = {...encounter};
        let model = { examination:{}};
        model.examination.diagnosis = {
            use: "3",
            rank: "1"
        };
        if (enc.preliminary_diagnosis) {
            model.examination.diagnosis.verificationStatus = '11';
        }
        if (enc.main_diagnosis_code) {
            model.examination.diagnosis.code = enc.main_diagnosis_code;
        }
        if (enc.concomitant_diseases_codes) {
            model.examination.additionalDiagnoses = enc.concomitant_diseases_codes.map((c, i) =>{
                    return {
                        use: "4",
                        rank: `${i + 2}`,
                        code: c
                    }
                }
            )
        }
        if (enc.additional_info) {
            model.examination.diagnosis.note = enc.additional_info;
        }
        if (enc.tests) {
            model.examination.tests = enc.tests.join("\n");
        } else {
            model.examination.tests = "";
        }
        if (enc.therapy) {
            model.examination.note = enc.therapy;
        }
        if (enc.conclusion) {
            model.examination.note = model.examination.note ? model.examination.note + "\n" + enc.conclusion : enc.conclusion;
            enc.therapy = enc.therapy ? enc.therapy + "\n" + enc.conclusion : enc.conclusion;
            enc.conclusion = "";
        }
        model.examination.objectiveCondition = enc.objective_data || "";
        model.examination.medicalHistory = enc.subjective_data || "";
        model.openDate=moment(enc.server_created_timestamp).valueOf();
        model.closeDate=moment(new Date()).valueOf();

        enc.nhis_examination_json = JSON.stringify(model);
        await onConvert(enc);
        setSuccess(true);
    }

    return <Modal backdrop={"static"} show={true} >
        <Modal.Body>
            <p>{success ? $$("nhis.examination.converted_successfully") : $$("nhis.examination.confirm_convert_body")}</p>
        </Modal.Body>
        {!success && <Modal.Footer>
            <button type="button" className="btn btn-danger"
                    onClick={convert}
            >{$$("nhis.btn.convert_to_nhis_exam")}</button>
            <button type="button" className="btn btn-secondary"
                    onClick={onClose}>{$$("nhis.btn.cancel")}</button>
        </Modal.Footer>}
        {success && <Modal.Footer>
            <button type="button" className="btn btn-secondary"
                    onClick={()=>onClose(true)}>{$$("close_btn_label")}</button>
        </Modal.Footer>}
    </Modal>
}